<template>
  <validation-observer ref="simpleRules">
    <b-row>
      <b-col cols="12">
        <price-main />
      </b-col>
      <b-col
        cols="12"
        md="8"
      >
        <customer-price
          :submit-form="submitForm"
          :submit-status="submitStatus"
        />
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <product-search />
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import { BRow, BCol } from 'bootstrap-vue'
import PriceMain from '@/views/Admin/Customer_prices/ItemForm/PriceMain.vue'
import ProductSearch from '@/views/Admin/Customer_prices/ItemForm/ProductSearch.vue'
import CustomerPrice from '@/views/Admin/Customer_prices/ItemForm/CustomerPrice.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'Add',
  components: {
    BRow,
    BCol,
    PriceMain,
    ProductSearch,
    CustomerPrice,
    ValidationObserver,
  },
  data() {
    return {
      required,
      submitStatus: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['customerPrices/dataItem']
    },
    saveData() {
      return this.$store.getters['customerPrices/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.$router.push('/app/customers/prices')
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getData()
    localize('tr')
    this.$store.commit('customerPrices/RESET_DATA_ITEM')
  },
  methods: {
    getData() {
      this.$store.dispatch('customerPrices/getDataItem', this.$route.params.id)
        .then(response => {
          if (response.id_customers) {
            this.$store.dispatch('customers/getDataItem', response.id_customers)
          }
        })
    },
    submitForm() {
      this.dataItem.id_customers = this.$route.params.id_customers
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.submitStatus = true
          this.$store.dispatch('customerPrices/saveData', this.dataItem)
        } else {
          this.showToast({
            title: 'Uyarı!',
            icon: 'InfoIcon',
            text: 'Lütfen tüm alanları doldurun.',
            variant: 'warning',
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
